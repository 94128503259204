import styled from 'styled-components'

export const Wrapper = styled.div`
`

export const ContentWrapper = styled.div`
.logout {
    position: absolute;
    bottom: 40px;
    padding-left: 25px;
}
`

export const TopWrapper = styled.div`
${({ theme, profilePic }) => `
padding-top: ${profilePic ? '0px' : '30px'};
text-align:center;
.MuiAvatar-root {
    height:${profilePic ? '120px' : '75px'};
    width:${profilePic ? '120px' : '100px'};
    margin 0 auto;
    padding-top: ${profilePic ? '40px' : '10px'};
    padding-bottom: ${profilePic ? '30px' : '10px'};
    margin-bottom: ${profilePic ? '0px' : '20px'};
}
.name {
    font-size: ${theme.fontSizes.name.small};
    font-weight: bold;
    color: ${theme.colors.lightBlue};
    padding-bottom: 15px;
}
.langSw {
    width: 68px;
    margin: 0 auto;
    padding-top: 15px;
}
.MuiAvatar-img {
    border-radius: 50%;
    box-shadow: ${theme.boxShadows.profilepic};
}
.select__single-value {
    font-weight: normal;
}
.fp {
    top: 0px !important;
}
`}`

export const MenuWrapper = styled.div`
${({ theme }) => `
    display: flex;
    flex-direction: column;
    padding-left: 25px;
    padding-top: 40px;
    span {
        font-size: ${theme.fontSizes.large};
        padding-bottom: 40px;
        @media screen and (max-width: 375px) and (max-height: 667px){
            padding-bottom: 25px;
          }
          @media screen and (device-aspect-ratio: 40/71) {
            padding-bottom: 15px;
          }
    }
`}`

export const AboutWrapper = styled.a`
    text-decoration: none;
    padding-bottom: 25px;
    color: #000;
`
