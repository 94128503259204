import React from 'react'

import Header from './Header'
import Footer from '../../components/Footer'

import { getLocalStore } from '../../utils/utils'

import {
  Container,
  FooterWrapper,
  SectionParaBox,
  SectionPara,
  AboutIPUImg,
  SectionReadMore,
  ReadMorePoint,
  ReadMoreText,
  ContentBox,
  SectionTitle,
  SectionLine,
  SectionParaBoxTitle
} from './style'

import AboutIPUImage from '../../assets/AboutIPUImg.png'
import ReadMoreBullet from '../../assets/Rectangle.png'
import ParliamentImage from '../../assets/parliament.png'

export default function LandingPage () {
  const auth = getLocalStore('AUTH_DETAILS_MOBILE')

  return (
    <Container>
      <Header />
      <SectionParaBox>
        <SectionPara>
          The IPU is the global organization of national parliaments. The IPU is
          the global organization of national parliaments.
        </SectionPara>
        <AboutIPUImg src={AboutIPUImage} />
        <SectionPara>
          What began in 1889 as a small group of parliamentarians, dedicated to
          promoting peace through parliamentary diplomacy and dialogue, has
          since grown into a truly global organization of national parliaments.
          Today, our membership inches ever closer to being universal, with{' '}
          <span className='site-link'>
            179 Member Parliaments ,13 Associate Members,
          </span>{' '}
          and increasing numbers of parliamentarians from all over the world
          involved in our work.
        </SectionPara>
        <SectionPara>Our slogan is “For democracy. For everyone.”</SectionPara>
        <SectionPara>
          Our vision is “We want a world where every voice counts, where
          democracy and parliaments are at the service of the people for peace
          and development.”
        </SectionPara>
        <SectionReadMore>
          <ReadMorePoint src={ReadMoreBullet} />
          <ReadMoreText href='https://www.ipu.org/' target='_blank'>
            Read More
          </ReadMoreText>
        </SectionReadMore>
      </SectionParaBox>
      <ContentBox>
        <SectionTitle>Parliamentarian App</SectionTitle>
        <SectionLine />
        <SectionParaBox>
          <SectionParaBoxTitle>1.Background</SectionParaBoxTitle>
          <SectionPara>The IPU</SectionPara>
          <SectionPara>
            Established in 1889, the Inter-Parliamentary Union (IPU) is the
            global organization of national parliaments and, with its 179 Member
            Parliaments and 13 Associate Member Parliamentary Assemblies, is a
            global forum for parliamentary dialogue, cooperation and action.
          </SectionPara>
          <SectionPara>
            The IPU advances democracy and assists parliaments and
            parliamentarians throughout the world to fulfill their mandates.
          </SectionPara>
          <SectionPara>
            The current IPU strategy 2017-2021 calls for an IPU contribution to
            peace building, conflict prevention and strengthening security,
            including taking action on counter terrorism and disarmament. In
            this regard, the IPU governing bodies agreed to the creation of a
            High-Level Advisory Group on Countering Terrorism and Violent
            Extremism (HLAG), which serves as the global parliamentary focal
            point for efforts to counter terrorism and violent extremism and
            related parliamentary activities. The role of the Group is to guide
            the Member-driven plan of action for parliaments to address
            terrorism and prevent violent extremism, as well as to bridge the
            significant gaps in the implementation of relevant IPU and United
            Nations resolutions.
          </SectionPara>
          <AboutIPUImg src={ParliamentImage} />
        </SectionParaBox>
        <SectionParaBox>
          <SectionParaBoxTitle>2. Purpose</SectionParaBoxTitle>
          <SectionPara>
            The Mobile App will be the link to achieve interconnectivity between
            the IPU and national parliaments. It will be a tool for outreach and
            visibility which would replicate the web platform and the Global
            Parliamentarian Network which the IPU is currently developing.
            Features would include secure login with access to all relevant
            documentation in the United Nations Office on Drugs and Crime
            (UNODC) Counter-terrorism Legislation Database and secure space for
            members of the HLAG. The Mobile App would also provide an overview
            of the activities and products that the IPU is in the process of
            implementating or developing, and those which have already been
            implemented or developed in the field of counter-terrorism and
            prevention of violent extremism. At the same time, it will be an
            application through which users can obtain first-hand information,
            as well as news notifications and information about events related
            to counter-terrorism or prevention of violent extremism.
          </SectionPara>
        </SectionParaBox>
      </ContentBox>
      <FooterWrapper>{auth && <Footer />}</FooterWrapper>
    </Container>
  )
}
