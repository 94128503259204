import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Wrapper } from './style'
import { useTranslation } from 'react-i18next'

import Drawer from './Drawer'
import DrawerLoggedIn from '../../components/HamburgerMenu'

import { getLocalStore } from '../../utils/utils'

import MenuImg from '../../assets/menu.png'

const Header = ({ setLangChange }) => {
  const auth = getLocalStore('AUTH_DETAILS_MOBILE')
  const history = useHistory()
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()
  const closeDrawer = () => {
    setIsOpen(!isOpen)
  }

  const openDrawer = () => {
    setIsOpen(!isOpen)
  }

  const { state: historyState } = history.location
  useEffect(() => {
    if (historyState?.name === 'fromMenu') {
      setIsOpen(!isOpen)
    }
  }, [historyState?.name])
  return (
    <Wrapper>
      <img src={MenuImg} id='menuIcon' onClick={() => openDrawer()} />
      <h2>{t('hamburger.about')}</h2>
      {
        auth
          ? <DrawerLoggedIn
              isOpen={isOpen}
              openDrawer={openDrawer}
              closeDrawer={closeDrawer}
              setLangChange={setLangChange}
              t={t}
            />
          : <Drawer
              isOpen={isOpen}
              openDrawer={openDrawer}
              closeDrawer={closeDrawer}
              setLangChange={setLangChange}
              t={t}
            />
        }
    </Wrapper>
  )
}

export default Header
