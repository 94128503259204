import { combineReducers } from '@reduxjs/toolkit'

import snackbarReducer from '../slices/snackbar.slice'
import sessionReducer from '../slices/session.slice'
import eventsReducer from '../slices/events.slice'
import userReducer from '../slices/user.slice'
import faqReducer from '../slices/faq.slice'
import newsReducer from '../slices/news.slice'
import articleReducer from '../slices/articles.slice'
import alertsReducer from '../slices/alerts.slice'
import messageReducer from '../slices/message.slice'
import staticReducer from '../slices/static.slice'
import hlagReducer from '../slices/hlag.slice'

const reducerList = {
  snackbar: snackbarReducer,
  session: sessionReducer,
  events: eventsReducer,
  user: userReducer,
  faq: faqReducer,
  news: newsReducer,
  articles: articleReducer,
  alerts: alertsReducer,
  message: messageReducer,
  static: staticReducer,
  hlag: hlagReducer
}

export default combineReducers(reducerList)
